import { Page } from '@inertiajs/inertia';
import { Link, usePage } from '@inertiajs/inertia-react';
import DndActionsLayer from 'Components/InventoryItems/DndActionsLayer';
import { Nav } from 'Components/Nav';
import { AddItemModal } from 'Modals/InventoryItems/AddItemModal';
import * as React from 'react';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { toast, ToastContainer, TypeOptions } from 'react-toastify';
import { AddItemOpenContext } from 'Support/Contexts/AddItemOpenContext';
import useSubscription from 'Support/Hooks/useSubscription';
import route from 'ziggy-js';

type MainLayoutProps = PropsWithChildren;

const useFlashNotifications = () => {
  const { flash } = usePage<
    Page<{
      flash?: {
        type: TypeOptions;
        message: string;
      }[];
    }>
  >().props;

  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({ type, message }) => {
        toast(message, { type });
      });
    }
  }, [JSON.stringify(flash)]);
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const {
    application: { site_name, keep_add_item_modal_open },
    page_title,
  } = usePage<
    Page<{
      page_title: string;
      site_name: string;
      application: {
        user: any;
        site_name: string;
        production: boolean;
      };
    }>
  >().props;

  useEffect(() => {
    document.title = page_title ? `${page_title} | ${site_name}` : site_name;
  }, [page_title, site_name]);

  useFlashNotifications();

  const { open: addItemModalOpen, setOpen: setAddItemModalOpen } = useContext(AddItemOpenContext);

  return (
    <div className="flex h-screen w-screen flex-col">
      <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="colored" position="top-right" />
      <Banner />
      <Nav />
      <div className="grow overflow-y-auto">{children}</div>
      <AddItemModal open={addItemModalOpen || keep_add_item_modal_open} onClose={() => setAddItemModalOpen(false)} />
      <DndActionsLayer />
    </div>
  );
};

const Banner = () => {
  const subscription = useSubscription();

  if (subscription.on_trial) {
    return (
      <div className="bg-primary px-6 py-2.5 sm:px-3.5">
        <p className="mx-auto max-w-screen-2xl text-center text-sm leading-6 text-white">
          You have {subscription.trial_days_remaining} days remaining on your PRO trial.{' '}
          <Link href={route('organization.index')} className="whitespace-nowrap font-semibold">
            Upgrade now&nbsp;<span aria-hidden="true">&rarr;</span>
          </Link>
        </p>
      </div>
    );
  }
};
