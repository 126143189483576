import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import { mdiCameraFlipOutline, mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import { axios } from '@webfox/webfox-ui';
import cls from 'classnames';
import EditableField from 'Components/EditableField';
import { CheckedOutUserBadge } from 'Components/InventoryItems/CheckedOutUserBadge';
import LocationPath from 'Components/Location/LocationPath';
import Tag from 'Components/Tags/Tag';
import MediaSection from 'Pages/InventoryItems/Partials/MediaSection';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { ScanButtonOpenCountContext } from 'Support/Contexts/ScanButtonOpenCountContext';
import useLocalStorage from 'Support/Hooks/useLocalStorage';
import route from 'ziggy-js';

const Detail = ({ title, editable, children, ...props }) => {
  return (
    <div className="flex flex-col gap-1">
      <h4 className="text-sm font-medium text-gray-500">{title}</h4>
      <div className="flex items-center gap-2">
        <EditableField disabled={!editable} {...props} textClassNames="text-base" iconProps={{ className: 'w-4' }} className="grow" {...props} />
        {children}
      </div>
    </div>
  );
};

const Item = ({ item }) => {
  return (
    <div className="flex w-full flex-col px-5 pt-10">
      <div className="flex w-full flex-col lg:flex-row">
        <img src={item?.latest_image?.original_url ?? '/no-image.png'} alt="" className="hidden h-60 w-60 rounded-md lg:block" />
        <div className="flex w-full flex-col gap-3 lg:mx-5 ">
          <div className="flex w-full justify-between">
            <div className="flex gap-2">
              <EditableField value={item?.name} disabled textClassNames="max-w-[14rem] truncate text-2xl font-semibold" />
            </div>
            <div>
              {item?.checked_out_to_id ? (
                <CheckedOutUserBadge user={item?.check_out_user} />
              ) : (
                <div key="check-out-status" className="flex items-center rounded bg-green-200 px-2 py-1 text-green-800 duration-300">
                  <span>Available</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <LocationPath location={item?.location} />
          </div>
          <div className="flex w-full flex-wrap items-center gap-2 pb-2">
            {item?.tags?.map((tag) => (
              <div key={tag.id} className="relative">
                <Tag tag={tag} />
              </div>
            ))}
          </div>
          <Detail as="div" title="Description" valueKey="description" value={item?.description} textarea />
          <div className="flex w-full flex-wrap justify-between gap-10 border-b border-gray-100 pb-2 lg:justify-start ">
            <Detail as="span" title="Owner" value={item?.organization?.name} />
            <Detail as="span" title="Reference" value={item?.reference} valueKey="reference" />
            <Detail as="div" title="Original Code" valueKey="existing_code" value={item?.code?.existing_code} />
          </div>

          <div className="flex w-full flex-wrap gap-10 pb-2 ">
            {item?.custom_fields?.map((field, index) => (
              <div className="col-span-full lg:col-auto">
                <Detail as="div" key={index} title={field.label} valueKey={field.name} value={field.value} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pt-10">
        <h2 className="pb-5 text-xl font-medium">Media</h2>
        <MediaSection media={item?.media} itemId={item?.id} />
      </div>
    </div>
  );
};
const ScanContent = ({ selectedItem: item }) => {
  const [showItems, setShowItems] = useState(false);
  const [newItem, setNewItem] = useState(false);
  useEffect(() => {
    setNewItem(true);
    setTimeout(() => {
      setNewItem(false);
    }, [200]);
  }, [item?.id]);

  return (
    <div
      className={cls(
        'absolute -bottom-16 flex w-full flex-col rounded-t-lg bg-white transition-all duration-200 ease-in-out',
        newItem && item ? '-translate-y-10 ' : 'translate-y-0'
      )}
    >
      <button onClick={() => setShowItems(!showItems)} className="flex justify-center">
        <div className={cls('mt-2 h-4 w-28 rounded-full bg-gray-100 transition-all duration-100')} />
      </button>
      <div className="p-6 px-8">
        {item ? <button onClick={() => setShowItems(!showItems)}>{item.name}</button> : <div className="animate-pulse">Waiting for scan...</div>}
      </div>
      <div className={cls(showItems ? 'h-96' : 'h-0', 'mx-6 border-t pt-24 transition-all duration-200')}>
        {item ? <Item item={item} /> : <div className="animate-pulse">Waiting for scan...</div>}
      </div>
    </div>
  );
};

export const ScanCheckModal = ({ open, onClose, scanModalData }) => {
  const [selectedDevice, setSelectedDevice] = useLocalStorage(0);
  const [devices, setDevices] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const pageProps = usePage().props;
  const { incrementCounter, decrementCounter } = useContext(ScanButtonOpenCountContext);
  useEffect(() => {
    if (open) {
      onClose();
    }
  }, [window.location.href]);

  useEffect(() => {
    if (selectedItem?.id) {
      navigator?.vibrate(200);
    }
  }, [selectedItem?.id]);

  useEffect(() => {
    if (!open) {
      setSelectedDevice(0);

      setSelectedItem(null);
    }

    if (open) {
      incrementCounter();
    } else {
      decrementCounter();
    }
  }, [open]);

  const onScan = useCallback(
    (err, data) => {
      if (data) {
        axios.get(route('scanner.check', { data: data.text })).then((response) => {
          const codeable = response.data;
          if (codeable?.type === 'location') {
            onClose();
            Inertia.get(
              route('locations.show', {
                location: codeable?.codeable?.id,
              })
            );
          }
          setSelectedItem(codeable.codeable);
        });
      }
    },
    [selectedItem?.id, setSelectedItem]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      setDevices(videoDevices);

      if (videoDevices.length === 1) {
        setSelectedDevice(0);
      } else if (videoDevices.length > 1) {
        const backFacing = videoDevices.findIndex((device) => device.getCapabilities().facingMode.includes('environment'));

        setSelectedDevice(backFacing >= 1 ? backFacing : 0);
      }
    });
  }, [open]);

  useEffect(() => {
    if (pageProps.code) {
      const code = pageProps.code;
      onScan({ text: JSON.stringify(code) });
    }
  }, [scanModalData]);

  if (!open) return null;

  return createPortal(
    <div className={cls('fixed left-0 top-0 z-[100] h-full w-full bg-gray opacity-100')}>
      {/*<QrReader*/}
      {/*  key={selectedDevice}*/}
      {/*  facingMode="rear"*/}
      {/*  onResult={onScan}*/}
      {/*  constraints={{*/}
      {/*    deviceId: devices[selectedDevice]?.deviceId,*/}
      {/*  }}*/}
      {/*  videoContainerStyle={{ height: '100vh', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}*/}
      {/*  videoStyle={{ height: '100vh', overflow: 'visible', objectFit: 'cover' }}*/}
      {/*/>*/}

      <div style={{ height: '100vh', overflow: 'visible', objectFit: 'cover' }}>
        <BarcodeScannerComponent
          width={'100%'}
          height={'100%'}
          videoConstraints={{ facingMode: 'environment', deviceId: devices[selectedDevice]?.deviceId }}
          onUpdate={onScan}
        />
      </div>

      <div className="absolute left-1/2 top-1/3 grid -translate-x-1/2 -translate-y-1/2 grid-cols-3">
        <div className="border-l-2 border-t-2 p-10" />
        <div />
        <div className="border-r-2 border-t-2 p-10" />
        <div />
        <div className="p-8" />
        <div />
        <div className="border-b-2 border-l-2 p-10" />
        <div />
        <div className="border-b-2 border-r-2 p-10" />
      </div>

      <button
        type="button"
        onClick={() => setSelectedDevice((selectedDevice + 1) % devices?.length)}
        className="absolute right-5 top-5 rounded-full bg-black p-2"
      >
        <Icon path={mdiCameraFlipOutline} className="h-6 text-white" />
      </button>

      <button
        type="button"
        onClick={() => {
          setSelectedItem(null);
          onClose();
        }}
        className="absolute left-5 top-5 rounded-full bg-black p-2"
      >
        <Icon path={mdiClose} className="h-6 text-white" />
      </button>

      <ScanContent selectedItem={selectedItem} />
    </div>,
    document.body
  );
};
