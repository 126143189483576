import { createInertiaApp, InertiaHead, Link, PageResolver } from '@inertiajs/inertia-react';
import { InertiaProgress, ProgressSettings } from '@inertiajs/progress';
import { PrimaryButton } from '@webfox/webfox-ui';
import AccountMenu from 'Components/AccountMenu';
import AppDragLayer from 'Components/DragLayer/AppDragLayer';
import { ScanButton } from 'Components/ScanButton';
import { AddItemModal } from 'Modals/InventoryItems/AddItemModal';
import * as React from 'react';
import { ComponentType, createElement, PropsWithChildren, ReactElement, StrictMode, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { FormErrorContextProvider, InitialApplicationContext } from 'Support/Contexts';
import 'Support/yupExtensions';
import { AddItemOpenContext, AddItemOpenProvider } from 'Support/Contexts/AddItemOpenContext';
import { ScanButtonOpenCountProvider } from 'Support/Contexts/ScanButtonOpenCountContext';
import route from 'ziggy-js';
import { accountMenuItems, navItems } from '../MainLayoutConfig';

// export const initGoogleAnalytics = () => {
//   if (typeof window.gtag === 'function') {
//     let initialPageView = true;
//     Inertia.on('navigate', (event) => {
//       if (initialPageView) {
//         initialPageView = false;
//       } else {
//         window.gtag('event', 'page_view', {
//           'page_location': event.detail.page.url,
//         });
//       }
//     });
//   }
// };
//
export const initErrorTracking = async () => {
  if (window.sentryDsn) {
    const Sentry = await import('@sentry/react');

    Sentry.init({
      dsn: window.sentryDsn,
      integrations: [new Sentry.BrowserTracing()],
      environment: window.sentryEnvironment,
      tracesSampleRate: window.sentryTracesSampleRate,
    });
  }
};

function ErrorFallback() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8 text-gray-600">
      <div className="text-xl font-medium">Something went wrong</div>
      <div>The developers have been notified</div>
      <div className="flex gap-4">
        <PrimaryButton onClick={() => window.location.reload()}>Reload</PrimaryButton>
        <Link href={route('logout')}>
          <PrimaryButton>Logout</PrimaryButton>
        </Link>
      </div>
    </div>
  );
}

export const initInertiaApp = ({
  resolve,
  layout: MainLayout,
  analytics = true,
  errorTracking = true,
  inertiaProgress = true,
}: {
  resolve: PageResolver;
  layout: ComponentType<PropsWithChildren>;
  analytics?: boolean;
  errorTracking?: boolean;
  inertiaProgress?: boolean | ProgressSettings;
}) => {
  // if (errorTracking) {
  //   rg4js('apiKey', window.raygunApiKey);
  //   rg4js('enableCrashReporting', true);
  //   rg4js('enablePulse', true);
  // }
  //
  // if (analytics) {
  //   initGoogleAnalytics();
  // }

  initErrorTracking();

  if (inertiaProgress) {
    InertiaProgress.init(
      typeof inertiaProgress === 'boolean'
        ? {
            showSpinner: true,
          }
        : inertiaProgress
    );
  }

  return createInertiaApp<Window['inertiaInitialPageData']['props']>({
    resolve,
    page: window.inertiaInitialPageData,
    setup({ el, App, props }) {
      createRoot(el).render(
        <StrictMode>
          <InitialApplicationContext.Provider value={{ application: props.initialPage.props.application }}>
            <App {...props}>
              {({ key, props, Component }) => {
                // @ts-ignore
                const componentLayout: (page: ReactElement) => ReactElement | Array<(page: ReactElement) => ReactElement> = Component.layout;
                const page = createElement(Component, { key, ...props });
                // useEffect(() => {
                //   if (Array.isArray(page.props.flash)) {
                //     page.props.flash.forEach(({ type, message }) => {
                //       toast(message, { type });
                //     });
                //   }
                // }, [JSON.stringify(page.props.flash)]);
                let children;
                if (Array.isArray(componentLayout)) {
                  children = componentLayout
                    .concat(page)
                    .reverse()
                    .reduce((children, Layout) => createElement(Layout, { children }));
                } else if (typeof componentLayout === 'function') {
                  children = componentLayout(page);
                } else {
                  children = (
                    <ScanButtonOpenCountProvider>
                      <AddItemOpenProvider>
                        <MainLayout
                          navItems={navItems}
                          accountMenu={<AccountMenu accountMenuItems={accountMenuItems} />}
                          largeLogoSrc="/wide-logo.png"
                          background="/logo.png"
                          smallLogoSrc="/logo.png"
                          site_name={page.props.application.site_name}
                          production={page.props.application.production}
                          // disableActionBar={true}
                        >
                          <ErrorBoundary FallbackComponent={() => <ErrorFallback />}>{page}</ErrorBoundary>
                          <ScanButton />
                        </MainLayout>
                      </AddItemOpenProvider>
                    </ScanButtonOpenCountProvider>
                  );
                }

                return (
                  <FormErrorContextProvider>
                    <DndProvider backend={HTML5Backend}>
                      <InertiaHead key={page.props.page_title}>
                        <title>{(page.props.page_title ? `${page.props.page_title} | ` : '') + page.props.application.site_name}</title>
                      </InertiaHead>
                      {children}
                      <AppDragLayer />
                    </DndProvider>
                  </FormErrorContextProvider>
                );
              }}
            </App>
          </InitialApplicationContext.Provider>
        </StrictMode>
      );
    },
  });
};
