import React from 'react';
import ImageDragItem from 'Support/DragItems/ImageDragItem';

const ImageDraggable = ({ data }) => {
  return <img className="max-h-40 rounded-lg opacity-30 duration-100 hover:z-10 hover:scale-105 hover:shadow-xl" src={data?.thumbnail_url} />;
};

ImageDraggable.type = ImageDragItem.type;

export default ImageDraggable;
