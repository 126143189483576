import {Inertia} from '@inertiajs/inertia';
import {mdiPackageDown, mdiPackageUp} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import React from 'react';
import {useDragLayer, useDrop} from 'react-dnd';
import InventoryItemDragItem from 'Support/DragItems/InventoryItemDragItem';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const ItemAction = ({name, icon, onDrop, canDrop: propCanDrop}) => {
  const [{isOver, canDrop}, drop] = useDrop({
    accept: InventoryItemDragItem.type,
    drop: (item, monitor) => {
      onDrop?.(item, monitor);
    },
    canDrop: (item) => propCanDrop?.(item) ?? true,
    collect: (monitor) => ({
      isOver: !!monitor.isOver() && !!monitor.canDrop(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      className={cls(
        'flex items-center justify-center gap-5 rounded-xl border shadow-xl backdrop-blur duration-100',
        isOver ? 'border-indigo-500 bg-indigo-100/75 text-indigo-500' : 'bg-white/75 text-gray-800',
        canDrop ? 'border-indigo-500' : 'pointer-events-none border-transparent opacity-25',
      )}
    >
      {icon}
      <span className="font-medium">{name}</span>
    </div>
  );
};

const DndActionsLayer = () => {
  const user = useUser();

  const {isDragging, itemType} = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }));

  const showLayer = isDragging && [InventoryItemDragItem.type].includes(itemType);

  return (
    <div
      className={cls(
        'fixed inset-x-0 z-50 grid h-40 auto-cols-fr grid-flow-col gap-5 bg-gradient-to-b from-gray-800/50 to-transparent p-5 duration-300',
        !showLayer && 'pointer-events-none opacity-0',
      )}
    >
      <ItemAction
        name="Check Out"
        icon={<Icon path={mdiPackageUp} className="w-5"/>}
        onDrop={(item) => {
          Inertia.post(
            route('scanner.checkout', {items: [item]}),
            {},
            {
              preserveState: false,
              preserveScroll: true,
            },
          );
        }}
        canDrop={(item) => item?.checked_out_to_id !== user?.id}
      />
      <ItemAction
        name="Check In"
        icon={<Icon path={mdiPackageDown} className="w-5"/>}
        onDrop={(item) => {
          Inertia.post(
            route('scanner.checkin', {items: [item], location: item.location_id}),
            {},
            {
              preserveState: false,
              preserveScroll: true,
            },
          );
        }}
        canDrop={(item) => item?.is_checked_out}
      />
    </div>
  );
};

export default DndActionsLayer;
