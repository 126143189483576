import { Dialog, Transition } from '@headlessui/react';
import { mdiLoading } from '@mdi/js';
import { Icon } from '@mdi/react';
import { PrimaryButton, SecondaryButton } from '@webfox/webfox-ui';
import { Fragment, useCallback, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Dimensions, EmptyDimensions, getCroppedImageBlob } from './imageSelectionHelpers';

export const CropModal = ({
  open,
  onCancel,
  onAccept,
  sourceDimensions,
  outputDimensions,
  aspectRatio: providedAspectRatio,
  source,
  circle = false,
  title,
  description,
}: {
  open: boolean;
  onCancel: () => void;
  onAccept: (imageBlob: Blob) => void;
  sourceDimensions: Dimensions | EmptyDimensions;
  outputDimensions: Dimensions;
  aspectRatio?: number;
  source: string | null;
  circle?: boolean;
  title: string;
  description?: string;
}) => {
  const [zoom, setZoom] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setSubmitting(false);
  }, []);

  const orientation = sourceDimensions.width && sourceDimensions.width > sourceDimensions.height ? 'landscape' : 'portrait';
  const aspectRatio = providedAspectRatio ? providedAspectRatio : outputDimensions.width / outputDimensions.height;
  const submitButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onCancel} initialFocus={submitButtonRef}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <Dialog.Panel className="relative flex w-full max-w-xl transform flex-col overflow-hidden bg-white text-center shadow-2xl transition-all sm:rounded-xl">
              <header className="m-4">
                <Dialog.Title>
                  <div className="text-xl">{title}</div>
                </Dialog.Title>
                {description ? (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>
                ) : null}
              </header>
              <section className="my-auto px-1">
                <div className="border-accent relative max-h-[80vh] w-full border-2" style={{ aspectRatio }}>
                  {source && (
                    <Cropper
                      image={source}
                      classes={{
                        cropAreaClassName: '!text-[rgba(255,255,255,0.5)]',
                        mediaClassName: 'max-w-none',
                      }}
                      crop={crop}
                      zoom={zoom}
                      aspect={aspectRatio}
                      cropShape={circle ? 'round' : 'rect'}
                      zoomSpeed={0.5}
                      showGrid={false}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      objectFit={orientation === 'landscape' ? 'vertical-cover' : 'horizontal-cover'}
                    />
                  )}
                </div>
              </section>
              <footer className="m-4 flex gap-x-2">
                <SecondaryButton type="button" className="focus:ring-primary" onClick={onCancel}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  type="button"
                  className="flex-grow"
                  onClick={async () => {
                    setSubmitting(true);
                    onAccept(
                      await getCroppedImageBlob(
                        source!,
                        sourceDimensions as Dimensions,
                        {
                          width: croppedAreaPixels.width,
                          height: croppedAreaPixels.height,
                        },
                        croppedAreaPixels as Area
                      )
                    );
                  }}
                  disabled={submitting}
                  ref={submitButtonRef}
                >
                  {submitting ? (
                    <div className="animate-spin">
                      <Icon path={mdiLoading} className="h-4 w-4" />
                    </div>
                  ) : (
                    <div>Continue</div>
                  )}
                </PrimaryButton>
              </footer>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
