import { mdiPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '@webfox/webfox-ui';
import cls from 'classnames';
import React from 'react';

export const Tag = ({ onClick, endButton, tag, editLocked, value}) => {
  const Element = onClick ? 'button' : 'div';

  return (
    <Tooltip content={tag?.description} disabled={!tag?.description}>
      <Element
        {...(!!onClick && { onClick: () => onClick(tag) })}
        className={cls(
          'relative flex h-6 w-fit select-none items-stretch items-center overflow-hidden',
          !!onClick && 'duration-150 hover:scale-105 hover:drop-shadow',
        )}
      >
        {value && <span className="text-xs text-center flex items-center">Create </span>}
        <div className="z-1 relative -mr-4 h-6 w-6 rotate-45 rounded-full bg-gray">
          <div className="z-1 absolute left-2 top-4 h-1 w-1 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white " />
        </div>
        <div className={cls('flex items-center gap-2 whitespace-nowrap rounded-l bg-gray text-sm font-medium text-black', editLocked ? 'rounded-r px-2' : 'pl-2 pr-1')}>
          <div className="z-10">{tag?.name ?? value}</div>
          {endButton}
        </div>
      </Element>
    </Tooltip>
  );
};

Tag.Button = ({ icon, ...props }) => (
  <button type="button" className="duration-150 hover:scale-125 hover:text-white hover:drop-shadow" {...props}>
    <Icon path={icon} className="w-4" />
  </button>
);

export default Tag;
