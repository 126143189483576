import { Inertia } from '@inertiajs/inertia';
import cls from 'classnames';
import ImageDraggable from 'Draggables/ImageDraggable';
import React from 'react';
import { useDrop } from 'react-dnd';
import route from 'ziggy-js';

export const ImageInbetween = ({ index }) => {
  const [{ isOver }, drop] = useDrop(
    {
      accept: [ImageDraggable.type],
      drop: (item, monitor) => {
        if (index === item.order_column || index === item.order_column - 1) return;
        const newIndex = index >= item.order_column ? index - 1 : index;
        Inertia.put(route('items.media.update-order', { media: item.id, archived_inventory_item: item.model_id, newIndex }));
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    },
    [index]
  );

  return (
    <div
      ref={(el) => drop(el)}
      className={cls(isOver && 'border border-green-400 bg-green-200', 'h-1/2 rounded-full bg-opacity-75 p-2 transition-all duration-300 lg:h-40')}
    ></div>
  );
};
