import { Inertia } from '@inertiajs/inertia';
import { mdiChevronUp, mdiQrcodeScan } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import { ScanCheckModal } from 'Components/Scanning/ScanCheckModal';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScanButtonOpenCountContext } from 'Support/Contexts/ScanButtonOpenCountContext';
import { ActionPanel } from './Scanning/ActionPanel';

export const ScanButton = () => {
  const [scanPanelOpen, setScanPanelOpen] = useState(false);
  const [scanCheckPanelOpen, setScanCheckPanelOpen] = useState(false);
  const { counter } = useContext(ScanButtonOpenCountContext);

  const actionPanelRef = useRef(null);
  const scanCheckButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionPanelRef?.current && !actionPanelRef?.current?.contains(event.target) && !scanCheckButtonRef?.current?.contains(event?.target)) {
        setScanPanelOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionPanelRef, scanCheckButtonRef]);

  return (
    <>
      <ScanCheckModal open={scanCheckPanelOpen} onClose={() => setScanCheckPanelOpen(false)} />

      {counter === 0 && (
        <div ref={scanCheckButtonRef} className="fixed bottom-14 right-20 z-[100] flex items-end gap-2 opacity-70 lg:hidden">
          <button
            onClick={() => setScanCheckPanelOpen(true)}
            className={cls(
              'flex transform overflow-hidden whitespace-nowrap rounded-full bg-primary p-3 text-white opacity-50 shadow-lg duration-100 hover:cursor-pointer hover:opacity-100'
            )}
          >
            <Icon path={mdiQrcodeScan} className={cls('h-7 min-w-7 transition-all duration-500 ease-in-out')} />
          </button>
        </div>
      )}
      <div ref={actionPanelRef} className="fixed bottom-14 right-2 z-[100] flex items-end gap-2 opacity-70 lg:hidden">
        <div className="flex flex-col items-end">
          <ActionPanel open={scanPanelOpen} onClose={() => setScanPanelOpen(false)} />
          {counter === 0 && (
            <button
              onClick={() => setScanPanelOpen(!scanPanelOpen)}
              className={cls(
                'flex transform overflow-hidden whitespace-nowrap rounded-full bg-primary p-3 text-white opacity-50 shadow-lg duration-100 hover:cursor-pointer hover:opacity-100'
              )}
            >
              <Icon path={mdiChevronUp} className={cls('h-7 min-w-7 transition-all duration-500 ease-in-out', scanPanelOpen && 'rotate-180')} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};
