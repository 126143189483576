import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { SearchModal } from 'Components/Search/SearchModal';
import { useEffect, useState } from 'react';

export const SearchBar = () => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (event.key === '/') {
        9421901492465;
        setSearchModalOpen(true);
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <div className="flex flex-1 items-center justify-center lg:ml-6 lg:justify-end lg:px-2">
      <div className="w-full lg:max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <button
            onClick={() => setSearchModalOpen(true)}
            className="block w-full border-0 bg-white px-10 py-1.5 text-start text-sm text-gray-400 focus:ring-0 sm:leading-6 lg:rounded-md lg:ring-1 lg:ring-inset lg:ring-gray-300 lg:focus:ring-2 lg:focus:ring-inset lg:focus:ring-indigo-600"
          >
            Search...
          </button>
          <div className="absolute inset-y-0 right-0 hidden px-1.5 py-1.5 lg:flex">
            <kbd className="inline-flex items-center rounded border border-gray-200 px-1.5 font-sans text-xs text-gray-400">/</kbd>
          </div>
        </div>
      </div>
      <SearchModal open={searchModalOpen} onClose={() => setSearchModalOpen(false)} />
    </div>
  );
};
