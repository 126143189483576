import {Inertia} from '@inertiajs/inertia';
import {usePage} from '@inertiajs/inertia-react';
import {mdiCameraFlipOutline, mdiClose, mdiFolderOutline, mdiMagnify, mdiPlus, mdiPlusBoxMultipleOutline} from '@mdi/js';
import {Icon} from '@mdi/react';
import {axios, PrimaryButton} from '@webfox/webfox-ui';
import cls from 'classnames';
import {SearchBar} from 'Components/Search/SearchBar';
import {UpgradeSubscription} from 'Modals/UpgradeSubscription';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import {toast} from 'react-toastify';
import useOrganization from 'Support/Hooks/useOrganization';
import {useLocalStorage} from 'usehooks-ts';
import route from 'ziggy-js';

const BottomButton = ({title, value, children, selectedContent}) => {
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [triggerShimmer, setTriggerShimmer] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTriggerShimmer(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [triggerShimmer]);

  useEffect(() => {
    if (value) {
      setTriggerShimmer(true);
    }
  }, [value]);
  return (
    <div className={cls('flex flex-col items-center gap-2')}>
      <div className={cls('text-sm', !!value ? 'text-green-500' : 'text-gray-500')}>{title}</div>
      <button
        onClick={() => setSelectModalOpen(true)}
        className={cls(
          'relative flex h-28 w-28 items-center justify-center overflow-hidden rounded-lg border',
          !!value ? 'border-green-500' : ' border-dashed border-black',
        )}
      >
        <div
          className={cls(
            triggerShimmer &&
            'shimmer overflow-hidden after:absolute after:inset-0 after:bg-gradient-to-r after:from-transparent after:via-green-500 after:to-transparent',
          )}
        />
        {value ? selectedContent : <Icon path={mdiPlus} className="h-6"/>}
      </button>

      <SelectModal open={selectModalOpen} onClose={() => setSelectModalOpen(false)} title={title} value={value}>
        {children}
      </SelectModal>
    </div>
  );
};

const SelectModal = ({open, onClose, children, title, value}) => {
  useEffect(() => {
    if (value) {
      onClose();
    }
  }, [value]);

  if (!open) return null;
  return (
    <div className="fixed left-0 top-0 h-screen w-screen bg-black/30 p-8">
      <div className="h-auto w-full rounded-lg bg-white p-4 shadow-xl">
        <div className="flex items-center justify-between pb-4">
          <button onClick={onClose} className="p-2 ">
            <Icon path={mdiClose} className="w-6"/>
          </button>
          <div className="text-lg font-medium">Select {title}</div>
          <div className="p-2"/>
        </div>
        <div className="auto">{children}</div>
      </div>
    </div>
  );
};

const CenterButton = ({bulkMode, setBulkMode, selectedItems}) => {
  const [upgradeSubscription, setUpgradeSubscription] = useState(false);
  const org = useOrganization();
  if (bulkMode && selectedItems?.length > 0)
    return <div className="flex h-16 w-16 items-center justify-center rounded-full border">{selectedItems.length}</div>;
  return (
    <>
      <button
        className="flex h-16 w-16 items-center justify-center rounded-full border"
        onClick={() => {
          if (!org.can_bulk_scan) {
            toast('Upgrade plan to access bulk scanning', {autoClose: 1000, type: 'warning'});
            return;
          }

          if (bulkMode) {
            toast('Bulk scan disabled', {autoClose: 1000, type: 'success'});
          } else {
            toast('Bulk scan enabled', {autoClose: 1000, type: 'success'});
          }

          setBulkMode(!bulkMode);
        }}
      >
        <Icon path={mdiPlusBoxMultipleOutline} className={cls('h-8 transition-all duration-200', bulkMode ? 'text-green-500' : 'text-gray-400')}/>
      </button>
      <UpgradeSubscription open={upgradeSubscription} onClose={() => setUpgradeSubscription(false)}/>
    </>
  );
};

const ScanContent = ({
  setSelectedItems,
  setLocation,
  selectedItems,
  bulkMode,
  setBulkMode,
  location,
  items: initialItems,
  locations: initialLocations,
  scanModalData,
  onConfirm,
}) => {
  const [showItems, setShowItems] = useState(false);
  const [itemsSearch, setItemsSearch] = useState('');
  const [locationsSearch, setLocationsSearch] = useState('');

  const items = useMemo(
    () => initialItems?.filter((item) => {
        if (scanModalData?.action === 'check-out' || scanModalData?.action === 'move') {
          return !item.checked_out_to_id;
        }
        if (scanModalData?.action === 'check-in') {
          return item.checked_out_to_id;
        }
      })
      .filter((item) => {
        if (itemsSearch) {
          return item.name.toLowerCase().includes(itemsSearch.toLowerCase());
        }
        return true;
      }),
    [initialItems, location, scanModalData?.action, itemsSearch],
  );


  const locations = useMemo(
    () => initialLocations.filter((location) => location.name.toLowerCase().includes(locationsSearch.toLowerCase())), [initialLocations, locationsSearch]);

  return (
    <div className="absolute bottom-0 flex w-full flex-col rounded-t-lg bg-white transition-all duration-100">
      {bulkMode && (
        <button onClick={() => setShowItems(!showItems)} className="flex justify-center">
          <div className={cls('rounded-full bg-gray-100 transition-all duration-100', bulkMode ? 'mt-2 h-2 w-28 ' : 'mt-0 h-0 w-0')}/>
        </button>
      )}
      <div className="flex w-full items-center justify-around px-4 pb-8 pt-4">
        {scanModalData?.action !== 'check-out' && (
          <BottomButton
            title="Location"
            value={location}
            selectedContent={
              <div className="flex w-full flex-col items-start p-2">
                <Icon path={mdiFolderOutline} className="h-10 w-10 rounded-lg text-gray-400"/>
                <div className="w-full truncate text-xs">{location?.name}</div>
              </div>
            }
          >
            <div className="flex items-center border-b mb-4 p-2 text-gray-400">
              <Icon path={mdiMagnify} className="h-6 w-6"/>
              <input
                className="ml-2 w-full border-none text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0"
                value={locationsSearch}
                placeholder="Search..."
                onChange={(event) => {
                  setLocationsSearch(event.target.value);
                }}
              />
            </div>
            <div className="flex h-96 flex-col gap-4 overflow-y-auto">
              {locations.map((location) => (
                <button key={location.id} onClick={() => setLocation(location)} className="rounded-lg border p-2 text-left">
                  <div className="text-sm">{location.name}</div>
                </button>
              ))}
            </div>
          </BottomButton>
        )}
        <div className="pt-6">
          <CenterButton bulkMode={bulkMode} setBulkMode={setBulkMode} selectedItems={selectedItems}/>
        </div>
        <BottomButton
          title="Items"
          value={selectedItems[selectedItems.length - 1]}
          selectedContent={
            <div className="flex w-full flex-col items-start gap-2 p-2">
              <img src={selectedItems[selectedItems.length - 1]?.latest_image?.original_url ?? '/no-image.png'} className="h-10 w-10 rounded-lg"/>
              <div className="flex w-full flex-col items-start overflow-hidden text-left leading-tight">
                <div className="w-full truncate text-xs">{selectedItems[selectedItems.length - 1]?.name}</div>
                <div className="text-xs text-gray-400">ref:{selectedItems[selectedItems.length - 1]?.reference}</div>
              </div>
            </div>
          }
        >
          <div className="flex items-center border-b mb-4 p-2 text-gray-400">
            <Icon path={mdiMagnify} className="h-6 w-6"/>
            <input
              className="ml-2 w-full border-none text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0"
              value={itemsSearch}
              placeholder="Search..."
              onChange={(event) => {
                setItemsSearch(event.target.value);
              }}
            />
          </div>
          <div className="flex h-96 flex-col gap-4 overflow-y-auto">
            {items.map((item) => {
              if (selectedItems.find((selectedItem) => selectedItem.id === item.id)) return null;
              return (
                <button
                  key={item.id}
                  onClick={() => {
                    if (bulkMode) {
                      setSelectedItems([...selectedItems, item]);
                    } else {
                      setSelectedItems([item]);
                    }
                  }}
                  className="flex w-full items-center gap-2 rounded-lg border p-2 text-left"
                >
                  <img src={item?.latest_image?.original_url ?? '/no-image.png'} className="h-10 w-10 rounded-lg"/>
                  <div className="flex flex-col gap-1">
                    <div className="text-sm">{item?.name}</div>
                    <div className="text-sm text-gray-400">ref:{item?.reference}</div>
                  </div>
                </button>
              );
            })}
          </div>
        </BottomButton>
      </div>

      {bulkMode ? (
        <div className={cls(showItems ? 'h-96' : 'h-0', 'mx-6 border-t pt-4 transition-all duration-200')}>
          <div className="text-lg font-medium text-gray-800">Items</div>
          <div className="h-full overflow-y-auto pb-10">
            {selectedItems.length == 0 ? (
              <div className="text-xs text-gray-500">No items selected</div>
            ) : (
              selectedItems.map((item) => (
                <div key={item?.id} className="flex justify-between border-b py-2">
                  <div className="flex items-center gap-2">
                    <img src={item?.latest_image?.original_url ?? '/no-image.png'} className="h-16 w-16 rounded-lg"/>
                    <div className="flex flex-col gap-1">
                      <div className="text-sm">{item?.name}</div>
                      <div className="text-sm text-gray-400">ref:{item?.reference}</div>
                    </div>
                  </div>
                  <button onClick={() => setSelectedItems(selectedItems.filter((value) => value?.id !== item?.id))}>
                    <Icon path={mdiClose} className="h-6 text-gray-400"/>
                  </button>
                </div>
              ))
            )}
            <div className="w-full pt-4">
              {scanModalData?.action === 'check-out' ? (
                selectedItems.length > 0 ? (
                  <PrimaryButton className="w-full" onClick={onConfirm}>
                    Confirm
                  </PrimaryButton>
                ) : null
              ) : selectedItems.length > 0 && location ? (
                <PrimaryButton className="w-full" onClick={onConfirm}>
                  Confirm
                </PrimaryButton>
              ) : null}
            </div>
          </div>
        </div>
      ) : scanModalData?.action === 'check-out' ? (
        selectedItems.length > 0 ? (
          <PrimaryButton onClick={onConfirm}>Confirm</PrimaryButton>
        ) : null
      ) : selectedItems.length > 0 && location ? (
        <PrimaryButton onClick={onConfirm}>Confirm</PrimaryButton>
      ) : null}
    </div>
  );
};

export const ScanModal = ({open, onClose, scanModalData}) => {
  const [selectedDevice, setSelectedDevice] = useLocalStorage('selected-device', null);
  const [devices, setDevices] = useState([]);
  const [location, setLocation] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const pageProps = usePage().props;
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [bulkMode, setBulkMode] = useState(false);

  //on navigate call onClose
  useEffect(() => {
    if (open) {
      onClose();
    }
  }, [window.location.href]);

  useEffect(() => {
    if (!open) {
      setSelectedDevice(0);
      setSelectedItems([]);
      setLocation(null);
      setBulkMode(false);
    }
  }, [open]);

  const onScan = useCallback(
    (data) => {
      if (data) {
        axios.get(route('scanner.check', {data: data.text})).then((response) => {
          const codeable = response.data;

          if (codeable.type === 'location') {
            setLocation(codeable.codeable);
          } else if (codeable.type === 'item') {
            setBulkMode((bulkMode) => {
              if (bulkMode) {
                setSelectedItems((value) => {
                  if (value.some((selectedItem) => selectedItem.id === codeable.codeable.id)) return value;
                  return [...value, codeable.codeable];
                });
              } else {
                setSelectedItems([codeable.codeable]);
              }
              return bulkMode;
            });
          }
        });
      }
    },
    [bulkMode, selectedItems.length],
  );

  const onMove = () => {
    Inertia.post(route('scanner.move', {location: location.id, items: selectedItems.map((item) => item.id)}));
    setLocation(null);
    setSelectedItems([]);
    onClose();
  };

  const onConfirm = () => {
    if (scanModalData?.action === 'check-out') {
      Inertia.post(route('scanner.checkout', {items: selectedItems.map((item) => item.id)}));
      setSelectedItems([]);
      onClose();
    } else {
      onMove();
    }
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      setDevices(videoDevices);

      if (videoDevices.length === 1) {
        setSelectedDevice(0);
      } else if (videoDevices.length > 1) {
        const backFacing = videoDevices.findIndex((device) => device.getCapabilities().facingMode.includes('environment'));

        if (!selectedDevice) {
          setSelectedDevice(backFacing >= 1 ? backFacing : 0);
        }
      }
    });
  }, [open]);

  useEffect(() => {
    axios.get(route('scanner.items')).then((response) => {
      setItems(response.data);
    });

    axios.get(route('scanner.locations')).then((response) => {
      setLocations(response.data);
    });

    if (open) {
      if (pageProps?.item && !pageProps.item.fixed_asset) {
        setSelectedItems([pageProps.item]);
        if (scanModalData?.action !== 'move') {
          setLocation(pageProps.item.location);
        }
      }
      if (pageProps?.location && scanModalData?.action !== 'move') {
        setLocation(pageProps.location);
      }
    } else {
      setSelectedItems([]);
      setLocation(null);
    }
  }, [open]);

  useEffect(() => {
    if (pageProps.code) {
      const code = pageProps.code;
      onScan({text: JSON.stringify(code)});
    }
  }, [scanModalData]);

  if (!open) return null;

  return createPortal(
    <div className={cls('fixed left-0 top-0 z-[100] h-full w-full bg-black opacity-100')}>
      {/*<QrReader*/}
      {/*  key={selectedDevice}*/}
      {/*  onResult={onScan}*/}
      {/*  constraints={{*/}
      {/*    deviceId: devices[selectedDevice]?.deviceId,*/}
      {/*  }}*/}
      {/*  videoContainerStyle={{ height: '100vh', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}*/}
      {/*  videoStyle={{ height: '100vh', overflow: 'visible', objectFit: 'cover' }}*/}
      {/*/>*/}

      <div style={{height: '100vh', overflow: 'visible', objectFit: 'cover'}}>
        <BarcodeScannerComponent
          width={'100%'}
          height={'110%'}
          videoConstraints={{facingMode: 'environment', deviceId: devices[selectedDevice]?.deviceId}}
          onUpdate={(err, result) => {
            if (result) {
              onScan(result);
            }
          }}
        />
      </div>

      <div className="absolute left-1/2 top-1/3 grid -translate-x-1/2 -translate-y-1/2 grid-cols-3">
        <div className="border-l-2 border-t-2 p-10"/>
        <div/>
        <div className="border-r-2 border-t-2 p-10"/>
        <div/>
        <div className="p-8"/>
        <div/>
        <div className="border-b-2 border-l-2 p-10"/>
        <div/>
        <div className="border-b-2 border-r-2 p-10"/>
      </div>

      <button type="button" onClick={() => setSelectedDevice((selectedDevice + 1) % devices?.length)} className="absolute right-5 top-5">
        <Icon path={mdiCameraFlipOutline} className="h-6 text-gray-400"/>
      </button>

      <button
        type="button"
        onClick={() => {
          setSelectedItems([]);
          setLocation(null);
          onClose();
        }}
        className="absolute left-5 top-5"
      >
        <Icon path={mdiClose} className="h-6 text-gray-400"/>
      </button>

      <ScanContent
        setBulkMode={setBulkMode}
        bulkMode={bulkMode}
        locations={locations}
        location={location}
        selectedItems={selectedItems}
        items={items}
        setSelectedItems={setSelectedItems}
        setLocation={setLocation}
        scanModalData={scanModalData}
        onConfirm={onConfirm}
      />
    </div>,
    document.body,
  );
};
