import { Tooltip } from '@webfox/webfox-ui';

export const CheckedOutUserBadge = ({ user }) => (
  <Tooltip content={`Checked out to ${user?.name}`}>
    <div className="flex items-center rounded border border-indigo-300 bg-white h-7 sm:h-9">
      <div>
        <img src={user?.avatar} className="mx-0.5 sm:mx-1 w-4 rounded-full bg-white py-1 lg:w-6 " />
      </div>
      <div className="rounded-r bg-indigo-100 flex justify-center items-center h-full px-0.5 sm:px-1 text-xs font-medium text-indigo-800">Checked Out</div>
    </div>
  </Tooltip>
);
