import { Link } from '@inertiajs/inertia-react';
import { mdiMapMarkerOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import React, { useMemo } from 'react';
import route from 'ziggy-js';

const LocationPath = ({ location, parentKey = 'ancestors' }) => {
  const path = useMemo(() => {
    let currentLocation = location;
    const path = [];

    path?.push(currentLocation);
    while (currentLocation?.[parentKey]) {
      currentLocation = currentLocation?.[parentKey];
      path?.unshift(currentLocation);
    }

    return path;
  }, [location, parentKey]);

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Icon path={mdiMapMarkerOutline} size={0.75} className="text-gray-400" />
      {path?.map((location, index, arr) => (
        <div key={location.id} className="contents">
          <Link href={route('locations.show', location.id)} className="text-sm text-gray-400 duration-100 hover:text-gray-500">
            {location.name}
          </Link>

          {index !== arr.length - 1 && <span className="text-lg text-gray-300">/</span>}
        </div>
      ))}
    </div>
  );
};

export default LocationPath;
