import {Inertia} from '@inertiajs/inertia';
import {usePage} from '@inertiajs/inertia-react';
import {mdiChevronDown} from '@mdi/js';
import {Icon} from '@mdi/react';
import {
  axios,
  Formik,
  Modal,
  SecondaryButton,
  SelectField,
  TextField,
  useInertiaForm,
  VerticalFieldWrapper as FieldWrapper,
} from '@webfox/webfox-ui';
import cls from 'classnames';
import {AddItemImageField} from 'Components/InventoryItems/AddItemImageField';
import {Form} from 'formik';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {ScanButtonOpenCountContext} from 'Support/Contexts/ScanButtonOpenCountContext';
import * as Yup from 'yup';
import route from 'ziggy-js';

const SaveButton = ({onSaveClick, onSaveAddClick}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <div className="relative flex justify-between rounded-lg text-white">
      <button
        type="button"
        disabled={openDropdown}
        onClick={onSaveClick}
        className={cls(openDropdown ? 'opacity-50' : 'opacity-100', 'rounded-l-lg bg-navy px-4 transition-all duration-100')}
      >
        Save
      </button>
      <button
        type="button"
        onClick={() => setOpenDropdown(!openDropdown)}
        className={cls(
          openDropdown ? 'rotate-180 rounded-l-lg border-r' : 'rounded-r-lg border-l',
          'overflow-hidden  bg-navy px-1 transition-all duration-200',
        )}
      >
        <Icon path={mdiChevronDown} size={1}/>
      </button>
      <button
        type="button"
        onClick={onSaveAddClick}
        className={cls(
          openDropdown ? 'h-auto opacity-100' : 'h-0 opacity-0',
          'fixed right-0 top-[98%] w-44 rounded-lg bg-navy p-1 py-3  transition-all duration-100',
        )}
      >
        Save & Add another
      </button>
    </div>
  );
};
export const AddItemModal = ({open, onClose}) => {
  const location = usePage()?.props?.location ?? null;

  const keepModalOpen = useRef(false);
  const [locations, setLocations] = useState(null);
  const {incrementCounter, decrementCounter} = useContext(ScanButtonOpenCountContext);

  useEffect(() => {
      axios
        .get(route('data-locations'))
        .then((response) => {
          setLocations(response.data.locations);
        })
        .catch((error) => {
          console.log(error);
        });

    if (open) {
      incrementCounter();
    } else {
      decrementCounter();
    }
  }, [open]);

  const formikProps = useInertiaForm({
    defaultValues: {
      name: '',
      location_id: location?.id ?? '',
      image: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide a name for this item'),
    }),
    submit: ({post, reset}) =>
      post(route('items.store', {keep_modal_open: keepModalOpen.current}), {
        onSuccess: () => {
          if (!keepModalOpen.current) {
            reset();
            onClose();
          }
        },
      }),
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Content withoutPadding>
        <Formik {...formikProps}>
          {({setFieldValue, submitForm, isSubmitting, values}) => {
            const onImageChange = (blob, url) => {
              setFieldValue('image', blob === null ? blob : new File([blob], 'image.webp', {type: 'image/webp'}));
            };

            useEffect(() => {
              if (isSubmitting) {
                setFieldValue('image', null);
                setFieldValue('name', '');
              }
            }, [isSubmitting]);

            return (
              <Form>
                <div className="flex flex-col gap-6 p-6 md:flex-row">
                  <div className="w-full space-y-6">
                    <FieldWrapper name="name" label="Name">
                      <TextField autoFocus/>
                    </FieldWrapper>
                    <FieldWrapper name="location_id" label="Location">
                      <SelectField options={locations} labelKey="name" valueKey="id"  menuPortalTarget={document.body}/>
                    </FieldWrapper>
                  </div>
                  <div className="flex w-full flex-col gap-2">
                    <span className="block text-sm font-medium text-gray-700">Images</span>
                    <AddItemImageField key={isSubmitting + 'image-field'} onChange={onImageChange} defaultImage={null}/>
                  </div>
                </div>
                <Modal.Actions>
                  <SecondaryButton
                    type="button"
                    onClick={() => {
                      decrementCounter();
                      Inertia.reload({preserveState: false});
                      onClose();
                    }}
                  >
                    Close
                  </SecondaryButton>

                  <SaveButton
                    onClick={submitForm}
                    onSaveAddClick={() => {
                      keepModalOpen.current = true;
                      submitForm();
                    }}
                    onSaveClick={() => {
                      keepModalOpen.current = false;
                      submitForm();
                    }}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
