import {Dialog, Disclosure, Menu, Transition} from '@headlessui/react';
import {ListBulletIcon} from '@heroicons/react/20/solid';
import {Bars3Icon, CodeBracketIcon, CubeIcon, InboxIcon, TagIcon, UserIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {Link} from '@inertiajs/inertia-react';
import cls from 'classnames';
import {SearchBar} from 'Components/Search/SearchBar';
import {CreateFeedbackModal} from 'Modals/CreateFeedbackModal';
import {Fragment, PropsWithChildren, useState} from 'react';
import useInitialApplication from 'Support/Hooks/useInitialApplication';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const mainNavigation = [
  {
    title: 'Locations',
    route: 'locations.index',
    active: 'locations.*',
    icon: InboxIcon,
  },
  {
    title: 'Items',
    route: 'items.index',
    active: 'items.*',
    icon: CubeIcon,
  },
];

const profileNavigation = [
  {
    title: 'Your Profile',
    route: 'users.show',
  },
  {
    title: 'Sign out',
    route: 'logout',
    link: true,
  },
];

export const Nav = () => {
  const {
    application: {site_name: siteName},
  } = useInitialApplication();

  const user = useUser();

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  return (
    <Disclosure as="nav" className="bg-gray-light border-b border-gray shadow-md shadow-gray">
      {({open, close: closeSidebar}) => (
        <>
          <div className="pr-4 lg:px-2">
            <div className="flex h-16 justify-start ">
              <div className="hidden pr-2 lg:flex">
                <Link href={route('locations.index')} className="flex flex-shrink-0 items-center">
                  <img className="block max-h-full w-36 py-1" src="/assettraqr-logo.png" alt={siteName}/>
                </Link>
                <div className="ml-10 flex space-x-8">
                  {mainNavigation.map(({route, title, active}) => (
                    <DesktopNavItem key={title} route={route} active={active}>
                      {title}
                    </DesktopNavItem>
                  ))}
                  {user?.role?.value === 'user' ? <DesktopNavItem route={'labels.index'}>Labels</DesktopNavItem> : null}
                  {['admin', 'owner', 'manager'].includes(user?.role?.value) && (
                    <DesktopMenuList title={'Settings'}>
                      <DesktopMenuItem route={'users.index'}>Users</DesktopMenuItem>
                      <DesktopMenuItem route={'tags.index'}>Tags</DesktopMenuItem>
                      <DesktopMenuItem route={'archive-actions.index'}>Archive Actions</DesktopMenuItem>
                      <DesktopMenuItem route={'custom-fields.index'}>Custom Fields</DesktopMenuItem>
                      <DesktopMenuItem route={'labels.index'}>Labels</DesktopMenuItem>
                      {user?.role?.value === 'owner' || user?.role?.value === 'admin' ? (
                        <DesktopMenuItem route={'organization.index'}>Account</DesktopMenuItem>
                      ) : null}
                    </DesktopMenuList>
                  )}
                  <button
                    onClick={() => setFeedbackModalOpen(true)}
                    className="border-transparent text-gray-dark hover:border-gray hover:text-black inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                  >
                    Feedback
                  </button>
                </div>
              </div>
              <div className="flex items-stretch lg:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-4 text-gray-700 hover:bg-gray hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                </Disclosure.Button>
              </div>
              <div className="my-auto h-6 w-px bg-gray lg:hidden" aria-hidden="true"></div>
              <SearchBar/>
              <div className="flex items-center lg:ml-4">
                {/* Additional Items e.g. notification bar could go here */}
                <ProfileMenu/>
              </div>
            </div>
          </div>
          <CreateFeedbackModal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)}/>
          <MobileSidebar open={open} closeSidebar={() => closeSidebar()}/>
        </>
      )}
    </Disclosure>
  );
};

const MobileSidebar = ({open = false, closeSidebar}: { open?: boolean; closeSidebar: () => void }) => {
  const user = useUser();
  const {
    application: {site_name: siteName},
  } = useInitialApplication();


  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const navItems = [
    ...mainNavigation,
    {
      title: 'Users',
      route: 'users.index',
      active: 'users.*',
      icon: UserIcon,
    },
    {
      title: 'Tags',
      route: 'tags.index',
      active: 'tags.*',
      icon: TagIcon,
    },
    {
      title: 'Custom Fields',
      route: 'custom-fields.index',
      active: 'custom-fields.*',
      icon: ListBulletIcon,
    },
    {
      title: 'Archive Actions',
      route: 'archive-actions.index',
      active: 'archive-actions.*',
      icon: InboxIcon,
    },
    {
      title: 'Account',
      route: 'organization.index',
      active: 'organization.*',
      icon: CubeIcon,
    },
  ];

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50 xl:hidden" onClose={closeSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80"/>
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={closeSidebar}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-10 w-auto" src="/assettraqr-logo.png" alt={siteName}/>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navItems.map(({icon: Icon, ...item}) => (
                            <li key={item.title}>
                              <a
                                href={route(item.route)}
                                className={cls(
                                  route().current(item.active ?? item.route)
                                    ? 'bg-gray-50 text-primary'
                                    : 'text-black hover:bg-gray-50 hover:text-primary-highlight',
                                  'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                )}
                              >
                                <Icon
                                  className={cls(route().current(item.active ?? item.route) ? 'text-primary' : 'text-gray-dark', 'h-6 w-6 shrink-0')}
                                  aria-hidden="true"
                                />
                                {item.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li className="-mx-6 mt-auto">
                        <button
                          onClick={() => {
                            closeSidebar();
                            setFeedbackModalOpen(true);
                          }}
                          className="flex items-center w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                        >
                          <CodeBracketIcon className="h-6 w-6 text-gray-dark" aria-hidden="true"/>
                          Feedback
                        </button>

                        <Link
                          href={route('dashboard')}
                          className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                        >
                          <img className="h-8 w-8 rounded-full bg-gray-50" src={user.avatar} alt={user.name}/>
                          <span className="sr-only">Your profile</span>
                          <div>
                            <div aria-hidden="true">{user.name}</div>
                            <div aria-hidden="true" className="font-normal leading-tight text-gray-500">
                              {user.email}
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <CreateFeedbackModal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)}/>

    </>
  );
};

const DesktopNavItem = ({
  children,
  route: routeName,
  active,
}: PropsWithChildren<{
  route: string;
}>) => {
  const baseClasses = 'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium';
  const standardClasses = 'border-transparent text-gray-dark hover:border-gray hover:text-black';
  const activeClasses = 'border-primary text-black';
  const isActive = route().current(active ?? routeName);

  return (
    <Link href={route(routeName)} className={cls(baseClasses, isActive ? activeClasses : standardClasses)}>
      {children}
    </Link>
  );
};
const DesktopMenuList = ({title, children}) => {
  const baseClasses = 'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium';
  const standardClasses = 'border-transparent text-gray-dark hover:border-gray hover:text-black';
  const activeClasses = 'border-primary text-black';

  return (
    <Menu as="div" className="relative z-[80] ml-4 flex flex-shrink-0 items-center">
      <div>
        <Menu.Button className={cls(baseClasses, standardClasses)}>{title}</Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 top-full z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const ProfileMenu = () => {
  const user = useUser();

  return (
    <Link
      href={route('users.show')}
      className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <span className="sr-only">Open user menu</span>
      <img className="h-8 w-8 rounded-full" src={user.avatar} alt={user.name}/>
    </Link>
  );
};

const DesktopMenuItem = ({
  children,
  route: routeName,
  link,
}: PropsWithChildren<{
  route: string;
  link?: boolean;
}>) => {
  const baseClasses = 'block px-4 py-2 text-sm text-black';
  const activeClasses = 'bg-gray';

  return (
    <Menu.Item>
      {({active}) =>
        link ? (
          <a href={route(routeName)} className={cls(baseClasses, active ? activeClasses : '')}>
            {children}
          </a>
        ) : (
          <Link href={route(routeName)} className={cls(baseClasses, active ? activeClasses : '')}>
            {children}
          </Link>
        )
      }
    </Menu.Item>
  );
};
