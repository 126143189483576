import React, { createContext, useState } from 'react';

export const ScanButtonOpenCountContext = createContext(null);
export const ScanButtonOpenCountProvider = ({ children }) => {
  const [counter, setCounter] = useState(0);

  const incrementCounter = () => {
    setCounter(counter + 1);
  };

  const decrementCounter = () => {
    if (counter === 0) {
      return;
    }
    setCounter(counter - 1);
  };

  return (
    <ScanButtonOpenCountContext.Provider value={{ counter, incrementCounter, decrementCounter, setCounter }}>
      {children}
    </ScanButtonOpenCountContext.Provider>
  );
};
