import { usePage } from '@inertiajs/inertia-react';
import cls from 'classnames';
import { UpgradeSubscription } from 'Modals/UpgradeSubscription';
import React, { useContext, useEffect, useState } from 'react';
import { AddItemOpenContext } from 'Support/Contexts/AddItemOpenContext';
import { ScanButtonOpenCountContext } from 'Support/Contexts/ScanButtonOpenCountContext';
import useOrganization from 'Support/Hooks/useOrganization';
import { ScanModal } from './ScanModal';

const Button = ({ title, onClick }) => (
  <button onClick={onClick} className="rounded-lg bg-primary p-2 text-white">
    <div className="w-full">{title}</div>
  </button>
);

export const ActionPanel = ({ open, onClose }) => {
  const [scanModalData, setScanModalData] = useState(null);
  const { setOpen: setAddItemModalOpen } = useContext(AddItemOpenContext);
  const [upgradeSubscription, setUpgradeSubscription] = useState(false);
  const location = usePage()?.props?.location ?? null;
  const { counter } = useContext(ScanButtonOpenCountContext);

  const org = useOrganization();

  const [invisible, setInvisible] = useState(open);

  useEffect(() => {
    if (open) {
      setInvisible(false);
    } else {
      setTimeout(() => setInvisible(true), 200);
    }
  }, [open]);

  useEffect(() => {
    if (counter === 0) {
      onClose();
    }
  }, [counter]);

  return (
    <>
      <ScanModal open={!!scanModalData} onClose={() => setScanModalData(null)} scanModalData={scanModalData} />
      <UpgradeSubscription open={upgradeSubscription} onClose={() => setUpgradeSubscription(false)} />
      {counter === 0 && (
        <div
          className={cls(
            'flex w-60 origin-bottom flex-col items-end space-y-4 p-4 transition-all duration-200',
            open ? 'scale-y-full ' : 'scale-y-0',
            invisible && 'hidden'
          )}
        >
          <Button title="Move" onClick={() => setScanModalData({ action: 'move', title: 'Move' })} />
          <Button title="Check In" onClick={() => setScanModalData({ action: 'check-in', title: 'Check In' })} />

          <Button title="Check Out" onClick={() => setScanModalData({ action: 'check-out', title: 'Check Out' })} />

          <Button title="Add New Item" onClick={() => (org?.can_add_items ? setAddItemModalOpen(true) : setUpgradeSubscription(true))} />
        </div>
      )}
    </>
  );
};
