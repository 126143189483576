import { CustomDragLayer } from 'Components/DragLayer/CustomDragLayer';
import React, { useSyncExternalStore } from 'react';

const onComponentRegister = [];
const registeredComponents = new Map();
export const registerDragComponent = (type, component = null) => {
  registeredComponents.set(type, component);

  onComponentRegister.forEach((callback) => callback(type, component));
};

Object.values(import.meta.glob('Draggables/**/*.{js,jsx}')).forEach((module) =>
  module().then((module) => {
    const Component = module.default;
    registerDragComponent(Component.type, Component);
  })
);

const subscribeToComponentRegister = (callback) => {
  onComponentRegister.push(callback);
  return () => {
    onComponentRegister.splice(onComponentRegister.indexOf(callback), 1);
  };
};

const AppDragLayer = () => {
  const Components = useSyncExternalStore(subscribeToComponentRegister, () => registeredComponents);

  return (
    <CustomDragLayer>
      {({ item, itemType, ...props }) => {
        return typeof Components.get(itemType) === 'function' && Components.get(itemType)({ data: item, type: itemType, ...props });
      }}
    </CustomDragLayer>
  );
};

export default AppDragLayer;
