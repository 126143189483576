import { Portal } from '@headlessui/react';
import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import React from 'react';

export const ImageModal = ({ className, image, id, onClose }) => (
  <Portal>
    <div
      className={cls(
        'fixed inset-0 z-[200] flex items-center justify-center bg-gray-700 transition transition-opacity duration-200 ease-in-out',
        className
      )}
    >
      <button onClick={onClose} className="h-full w-full hover:cursor-pointer"></button>
      <img src={image} className="max-h-full" alt="Post Image" />
      <button onClick={onClose} className="h-full w-full hover:cursor-pointer"></button>
      <button onClick={onClose} className="absolute right-5 top-5 flex justify-end ">
        <Icon path={mdiClose} className="w-12 rounded-xl bg-gray p-2 text-red" />
      </button>
    </div>
  </Portal>
);
