import { Modal, SecondaryButton, PrimaryButton } from '@webfox/webfox-ui';
import { Link } from '@inertiajs/inertia-react';
import route from 'ziggy-js';
export const UpgradeSubscription = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Content withoutPadding>
        <div className="flex flex-col items-center gap-6 p-6">
          <div className="flex w-full justify-center text-center text-2xl font-medium">Upgrade your subscription.</div>
          <div className="w-full text-center text-gray-700">To access this feature, upgrade your subscription.</div>
        </div>
        <Modal.Actions>
          <Link href={route('organization.index')}>
            <PrimaryButton type="button">Upgrade</PrimaryButton>
          </Link>
          <SecondaryButton type="button" onClick={onClose}>
            Close
          </SecondaryButton>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};
