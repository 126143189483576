import { mdiAccountOutline, mdiHomeOutline, mdiLogout, mdiMapMarkerOutline, mdiPackageVariant, mdiTagOutline } from '@mdi/js';
import route from 'ziggy-js';

export const navItems = [
  {
    title: 'Dashboard',
    href: route('dashboard'),
    icon: mdiHomeOutline,
    active: 'dashboard',
  },
  {
    title: 'Items',
    href: route('items.index'),
    icon: mdiPackageVariant,
    active: 'items.*',
  },
  {
    title: 'Locations',
    href: route('locations.index'),
    icon: mdiMapMarkerOutline,
    active: 'locations.*',
  },
  {
    title: 'Tags',
    href: route('tags.index'),
    icon: mdiTagOutline,
    active: 'tags.*',
  },
  {
    title: 'Users',
    href: route('users.index'),
    icon: mdiAccountOutline,
    active: 'users.*',
  },
].filter((item) => typeof item.filter !== 'function' || item.filter());

export const accountMenuItems = [
  {
    title: 'Log out',
    href: route('logout'),
    icon: mdiLogout,
  },
];
