import {Page} from '@inertiajs/inertia';
import {usePage} from '@inertiajs/inertia-react';

type PlanProps = {
  user_limit: number;
  base_item_limit: number;
  additional_item_limit: number;
};

export type ProPlan = {
  product: 'pro';
  on_trial: false;
} & PlanProps;

export type BasePlan = {
  product: 'base'
  on_trial: false;
} & PlanProps

export type ProPlanTrial = {
  product: 'pro';
  on_trial: true;
  trial_ends_at: string;
  trial_days_remaining: number;
} & PlanProps;

export type FreePlan = {
  product: 'free';
  on_trial: false;
} & PlanProps;

export type Plan = ProPlan | BasePlan | ProPlanTrial | FreePlan;

export default () => {
  const page = usePage<Page<{ application: { subscription: Plan } }>>();
  return page.props.application.subscription;
};