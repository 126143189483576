import {Inertia} from '@inertiajs/inertia';
import {Link} from '@inertiajs/inertia-react';
import {mdiMagnify} from '@mdi/js';
import {Icon} from '@mdi/react';
import {Modal} from '@webfox/webfox-ui';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useDebounce} from 'usehooks-ts';
import route from 'ziggy-js';

export const SearchModal = ({open, onClose}) => {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    axios.get(route('search', {search: debouncedSearch})).then((response) => {
      setResults(response.data);
    });
  }, [debouncedSearch]);

  return (
    <Modal open={open} onClose={onClose} size="3xl">
      <Modal.Content withoutPadding>
        <div className="flex flex-col">
          <div className="flex items-center border-b p-2 text-gray-400">
            <Icon path={mdiMagnify} className="h-6 w-6"/>
            <input
              className="ml-2 w-full border-none text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0"
              value={search}
              placeholder="Search..."
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </div>
          <div className="p-2 text-sm text-gray-400">
            {results?.items?.length === 0 && results?.locations?.length === 0 ? (
              <div className="flex items-center gap-2">No results found.</div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="text-lg text-gray-900">Items</div>
                {results?.items?.map((item) => (
                  <button
                    onClick={() => {
                      Inertia.get(route('items.show', {archived_inventory_item: item}));
                      onClose();
                    }}
                    key={item.id}
                    className="flex w-full items-center gap-2 rounded-lg border p-2 text-left transition-all duration-200 hover:shadow"
                  >
                    <img src={item?.latest_image?.thumbnail_url ?? '/no-image.png'} className="h-10 w-10 rounded-lg"/>
                    <div className="flex flex-col gap-1">
                      <div className="text-sm">{item?.name}</div>
                      <div className="text-sm text-gray-400">ref:{item?.reference}</div>
                    </div>
                  </button>
                ))}
                <div className="text-lg text-gray-900">Locations</div>
                {results?.locations?.map((location) => (
                  <Link
                    key={location.id}
                    href={route('locations.show', location)}
                    className="rounded-lg border p-2 text-left transition-all duration-200 hover:shadow"
                  >
                    <div className="text-sm">{location.name}</div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
