import {mdiCamera} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import {useEffect, useState} from 'react';
import Dropzone from 'react-dropzone';
import {CropModal} from '../ImageCropper/CropModal';
import {useImageSelectionReducer} from '../ImageCropper/useImageSelectionReducer';

export const AddItemImageField = ({defaultImage, onChange}) => {
  const [photoSrc, setPhotoSrc] = useState(defaultImage);

  const [[replacePhotoState, dispatchImageCropAction], dropzoneConfig, cropModalConfig] = useImageSelectionReducer({
    outputDimensions: {width: 250, height: 250},
    maxSizeMB: 10,
  });

  useEffect(() => {
    onChange(replacePhotoState.croppedBlob, replacePhotoState.croppedUrl, {
      reset: () => dispatchImageCropAction({type: 'reset'}),
    });
  }, [replacePhotoState.croppedBlob]);

  useEffect(() => {
    if (replacePhotoState.croppedUrl) {
      setPhotoSrc(replacePhotoState.croppedUrl);
    } else {
      setPhotoSrc(defaultImage);
    }
  }, [replacePhotoState.croppedUrl]);



  return (
    <div>
      <Dropzone {...dropzoneConfig} preventDropOnDocument>
        {({getRootProps, getInputProps, isFocused, isDragReject, isDragAccept}) => (
          <div className={cls(replacePhotoState.croppedUrl ? 'h-full' : 'h-14 sm:h-auto', 'group relative')}>
            <div
              {...getRootProps({
                className: cls(replacePhotoState.croppedUrl ? 'h-full md:aspect-1' : 'h-14 sm:h-auto ', 'group cursor-pointer md:aspect-1  ring-offset-3 relative min-h-24 flex-shrink-0 overflow-hidden rounded-lg'),
              })}
            >
              <input className={cls(replacePhotoState.croppedUrl ? 'h-full' : 'h-14 sm:h-auto')} {...getInputProps()} />
              {isDragReject ? (
                <div
                  className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center rounded-lg bg-red-200/50 text-gray-700 backdrop-blur-sm">
                  <span className="bg-gray-700/50 px-1 py-0.5 text-xs font-semibold text-white">Invalid Photo</span>
                </div>
              ) : null}
              {isDragAccept ? (
                <div
                  className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center rounded-lg bg-green-200/50 text-gray-700 backdrop-blur-sm">
                  <span className="bg-gray-700/50 px-1 py-0.5 text-xs font-semibold text-white">Change Photo</span>
                </div>
              ) : null}
              {photoSrc ? (
                <>
                  <div
                    className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center rounded-lg bg-white/50 text-gray-700 opacity-0 backdrop-blur-sm group-hover:opacity-100 group-focus:opacity-100">
                    <span className="bg-gray-700/50 px-1 py-0.5 text-xs font-semibold text-white">Change Photo</span>
                  </div>
                  <img src={photoSrc} alt="avatar" className="pointer-events-none"/>
                </>
              ) : (
                <div
                  className="pointer-events-none absolute inset-0 flex flex-col h-14 sm:h-auto items-center justify-center rounded-lg bg-gray-100 text-gray-700 group-hover:bg-gray-200">
                  <Icon path={mdiCamera} size={0.75}/>
                  <span className="stroke-white stroke-2 text-xs font-semibold">Add Photo</span>
                </div>
              )}
            </div>
            {photoSrc && (
              <div
                className={`absolute bottom-0 right-0 flex h-6 w-6 items-center justify-center rounded-full text-white ${
                  isFocused ? 'bg-gray-700/50' : 'bg-gray-700 group-hover:bg-gray-700/50'
                }`}
              >
                <Icon path={mdiCamera} size={0.75}/>
              </div>
            )}
          </div>
        )}
      </Dropzone>
      <CropModal
        key={replacePhotoState.sourceUrl}
        title="Move and Scale"
        description="Move and scale the image to select just the parts you want to keep."
        {...cropModalConfig}
      />
    </div>
  );
};
