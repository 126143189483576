import { Inertia } from '@inertiajs/inertia';
import { mdiPencil } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import React, { useState } from 'react';

const currencyFormat = (value, decimals = 0) => {
  return isNaN(parseFloat(value))
    ? ''
    : '$' +
        parseFloat(value)
          .toFixed(decimals)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const EditableField = ({
  as = 'h1',
  route,
  value: initialValue,
  valueKey = 'name',
  icon = mdiPencil,
  iconProps,
  inputClasses,
  inputProps,
  inertiaProps,
  textarea,
  textClassNames = 'text-2xl font-semibold',
  className,
  iconClassName = 'w-6 text-gray-dark',
  disabled,
  allowNull,
  editOverride,
  currency,
  ...props
}) => {
  const TextElement = as;
  const [nameStatus, setNameStatus] = useState(null);
  const [value, setValue] = useState(initialValue);

  const formatValue = (value) => {
    if (currency) {
      value = value.replace(/\D*/g, '');
      value = parseFloat(value);
      return value;
    }

    return value;
  };

  const onUpdateName = (e) => {
    // if (e.target.value === value?.toString()) return setNameStatus(null); unsure what this does but it was preventing the value from updating
    Inertia.visit(route, {
      method: 'patch',
      preserveState: true,
      preserveScroll: true,
      ...inertiaProps,
      data: {
        [valueKey]: e.target.value ? formatValue(e.target.value) : '',
        ...inertiaProps?.data,
      },
      onStart: (...props) => {
        inertiaProps?.onStart?.(...props);
        setNameStatus('updating');
      },
      onSuccess: (...props) => {
        inertiaProps?.onSuccess?.(...props);
        setNameStatus(null);
      },
    });
  };

  const onClickDisplayText = () => {
    if (disabled && !textarea) return;
    setNameStatus('editing');
  };

  const Input = textarea ? 'textarea' : 'input';

  return (
    <div className={cls('relative flex max-w-[80vw] gap-2 overflow-x-auto', textarea ? 'items-start' : 'items-center', className)} {...props}>
      {(!disabled && nameStatus) || editOverride ? (
        // {true ? (
        <div className="relative">
          <span className="invisible line-clamp-1">{value || 'N/A'}</span>
          <Input
            id="name"
            onBlur={onUpdateName}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => (e.key === 'Enter' && !textarea ? onUpdateName(e) : null)}
            defaultValue={value}
            disabled={nameStatus === 'updating'}
            type={currency ? 'number' : 'text'}
            className={cls(
              textClassNames,
              inputClasses,
              'spinners-none ring-none disabled:bg-gray-light -m-1 rounded p-1 outline-none duration-100 selection:bg-indigo-500 selection:text-white focus:outline-none focus:ring-0 disabled:text-gray-dark',
              textarea
                ? 'resize-none rounded-md border p-2 focus:border-gray-100'
                : 'absolute inset-0 border-none underline decoration-primary decoration-1 underline-offset-[0.2em] disabled:decoration-gray-300'
            )}
            autoFocus
            {...inputProps}
          />
        </div>
      ) : (
        <>
          <TextElement
            onClick={onClickDisplayText}
            className={cls(textClassNames, textarea && '-m-1 cursor-text resize-none rounded-md border border-transparent p-2')}
          >
            {value ? (currency ? currencyFormat(value) : value) : 'N/A'}
          </TextElement>
          {!disabled && (
            <button onClick={() => setNameStatus('editing')} type="button" className="opacity-10">
              <Icon path={icon} className={iconClassName} {...iconProps} />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default EditableField;
