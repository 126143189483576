import {
  Formik,
  Modal,
  PrimaryButton,
  RadioGroupField,
  SecondaryButton,
  TextareaField,
  useInertiaForm,
  VerticalFieldWrapper as FieldWrapper,
} from '@webfox/webfox-ui';
import {Form} from 'formik';
import React, {useContext, useEffect} from 'react';
import {ScanButtonOpenCountContext} from 'Support/Contexts/ScanButtonOpenCountContext';
import * as Yup from 'yup';
import route from 'ziggy-js';

export const CreateFeedbackModal = ({open, onClose}) => {

  const {incrementCounter, decrementCounter} = useContext(ScanButtonOpenCountContext);

  useEffect(() => {
    if (open) {
      incrementCounter();
    } else {
      decrementCounter();
    }
  }, [open]);

  const formikProps = useInertiaForm({
    defaultValues: {
      type: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required('Please select a type'),
      message: Yup.string().required('Please enter a message'),
    }),
    submit: ({post, reset}) =>
      post(route('create-user-report'), {
        onSuccess: () => {
          reset();
          onClose();
        },
      }),
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Content withoutPadding>
        <Formik {...formikProps}>
          {({setFieldValue, submitForm, isSubmitting, values}) => {
            return (
              <Form>
                <div className="flex flex-col gap-6 p-6">
                    <FieldWrapper label="Type" name="type" className="w-full">
                      <RadioGroupField
                        name="type"
                        placeholder="Select a type"
                        options={[
                          {label: 'Bug', value: 'bug'},
                          {label: 'Feature Request', value: 'feature_request'},
                          {label: 'General Feedback', value: 'general_feedback'},
                        ]}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Message" name="message" className="w-full">
                      <TextareaField name="message" placeholder="Enter a message"/>
                    </FieldWrapper>
                </div>
                <Modal.Actions>
                  <SecondaryButton
                    type="button"
                    onClick={() => {
                      decrementCounter();
                      onClose();
                    }}
                  >
                    Close
                  </SecondaryButton>
                  <PrimaryButton type={'submit'} disabled={isSubmitting}>
                    Submit
                  </PrimaryButton>
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
