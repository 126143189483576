import { fromEvent } from 'file-selector';
import { Dispatch, useCallback, useReducer } from 'react';
import { DropEvent, DropzoneProps, ErrorCode, FileError, FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Dimensions, EmptyDimensions, FileWithDimensions, getImageObject } from './imageSelectionHelpers';

export interface ReplacePhotoState {
  sourceFile: File | null;
  sourceUrl: string | null;
  sourceDimensions: Dimensions | EmptyDimensions;
  croppedBlob: Blob | null;
  croppedUrl: string | null;
  cropModalOpen: boolean;
}

export type ReplacePhotoAction =
  | {
      type: 'setSource';
      payload: {
        sourceFile: File;
        sourceUrl: string;
        sourceDimensions: Dimensions;
      };
    }
  | {
      type: 'setCroppedFile';
      payload: {
        croppedBlob: Blob;
      };
    }
  | { type: 'reset' };

const ReplacePhotoDefaultState: ReplacePhotoState = {
  sourceFile: null,
  sourceUrl: null,
  sourceDimensions: {
    width: null,
    height: null,
  },
  croppedBlob: null,
  croppedUrl: null,
  cropModalOpen: false,
};

export const acceptedFileTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
};

type UseImageCropReducerReturn = [
  [ReplacePhotoState, Dispatch<ReplacePhotoAction>],
  Partial<DropzoneProps>,
  {
    open: boolean;
    onCancel: () => void;
    onAccept: (imageBlob: Blob) => void;
    outputDimensions: Dimensions;
    sourceDimensions: Dimensions | EmptyDimensions;
    source: string | null;
  }
];

export function useImageSelectionReducer({
  outputDimensions,
  maxSizeMB = 10,
}: {
  outputDimensions?: Dimensions;
  maxSizeMB?: number;
}): UseImageCropReducerReturn {
  const [imageCropState, dispatchImageCropAction] = useReducer((state: ReplacePhotoState, action: ReplacePhotoAction): ReplacePhotoState => {
    console.log(action);

    if (action.type === 'reset' || action.type === 'setSource') {
      state.sourceUrl && URL.revokeObjectURL(state.sourceUrl);
      state.croppedUrl && URL.revokeObjectURL(state.croppedUrl);
    }

    if (action.type === 'reset') {
      return ReplacePhotoDefaultState;
    }

    if (action.type === 'setSource') {
      return {
        ...action.payload,
        croppedBlob: null,
        croppedUrl: null,
        cropModalOpen: outputDimensions !== undefined,
      };
    }

    if (action.type === 'setCroppedFile') {
      return {
        ...state,
        ...action.payload,
        croppedUrl: URL.createObjectURL(action.payload.croppedBlob),
        cropModalOpen: false,
      };
    }

    return ReplacePhotoDefaultState;
  }, ReplacePhotoDefaultState);

  const getFilesFromEvent = async (event: DropEvent) => {
    const files = await fromEvent(event);

    if (!['drop', 'change'].includes(event.type)) {
      return files;
    }

    return Promise.all(
      Array.from(files).map(async (file: FileWithDimensions): Promise<FileWithDimensions> => {
        if (!(file.type in acceptedFileTypes)) {
          return file;
        }

        const imageUrl = URL.createObjectURL(file);
        const image = await getImageObject(imageUrl);
        file.dimensions = { width: image.width, height: image.height };
        URL.revokeObjectURL(imageUrl);
        return file;
      })
    );
  };

  const fileValidator = useCallback((file: File | FileWithDimensions): FileError | null => {
    if ('dimensions' in file) {
      if (file.dimensions.width < (outputDimensions?.width || 300) || file.dimensions.height < (outputDimensions?.height || 300)) {
        return {
          code: 'image-too-small',
          message: `Please select an image that is at least ${outputDimensions?.width || 300}x${outputDimensions?.height || 300}px.`,
        };
      }
    }

    return null;
  }, []);

  const onDrop = useCallback(<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
    if (fileRejections.length) {
      const message = {
        [ErrorCode.FileInvalidType]: () => {
          const extensions = Object.values(acceptedFileTypes).flat();
          const lastExtension = extensions.pop();
          return `Please select an image of type ${extensions.join(', ')} or ${lastExtension}.`;
        },
        [ErrorCode.TooManyFiles]: () => 'Please select only one image.',
        [ErrorCode.FileTooLarge]: () => `File must be smaller than ${maxSizeMB}MB.`,
      };

      const firstRejection = fileRejections[0];
      const firstError = firstRejection.errors[0];
      toast(message[firstError.code]?.(firstError, firstRejection) ?? firstError.message, { type: 'error', autoClose: 5000 });
    }

    if (acceptedFiles.length) {
      const newPhotoUrl = URL.createObjectURL(acceptedFiles[0]);
      const img = new Image();
      img.addEventListener('load', () => {
        dispatchImageCropAction({
          type: 'setSource',
          payload: {
            sourceFile: acceptedFiles[0],
            sourceUrl: newPhotoUrl,
            sourceDimensions: {
              width: img.width,
              height: img.height,
            },
          },
        });
      });
      img.src = newPhotoUrl;
    }
  }, []);

  return [
    [imageCropState, dispatchImageCropAction],
    {
      accept: acceptedFileTypes,
      validator: fileValidator,
      getFilesFromEvent: getFilesFromEvent,
      onDrop: onDrop,
      maxSize: maxSizeMB * 1024 * 1024,
      multiple: false,
      useFsAccessApi: false,
    },
    {
      open: imageCropState.cropModalOpen,
      onCancel: () => dispatchImageCropAction({ type: 'reset' }),
      onAccept: (croppedBlob) => dispatchImageCropAction({ type: 'setCroppedFile', payload: { croppedBlob } }),
      outputDimensions: outputDimensions,
      sourceDimensions: imageCropState.sourceDimensions,
      source: imageCropState.sourceUrl,
    },
  ];
}
