export type Area = { width: number; height: number; x: number; y: number };

export type EmptyDimensions = { width: null; height: null };
export type Dimensions = { width: number; height: number };

export interface FileWithDimensions extends File {
  dimensions: Dimensions;
}

export const getImageObject = (url: string): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });
};

export const createCanvas = async (width: number, height: number, src?: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error(`Failed to get the canvas context`);
  }

  if (src) {
    ctx.drawImage(await getImageObject(src), 0, 0);
  }

  return { canvas, ctx };
};

export const getCroppedImageBlob = async (
  sourceUrl: string,
  sourceDimensions: Dimensions,
  outputDimensions: Dimensions,
  cropArea: Area,
  outputType: 'image/png' | 'image/jpeg' | 'image/webp' = 'image/webp',
  outputQuality = 0.8
) => {
  const photon = await import('@silvia-odwyer/photon');

  console.time('getCroppedImage');

  const { canvas } = await createCanvas(sourceDimensions.width, sourceDimensions.height, sourceUrl);
  const croppedImage = photon.crop_img_browser(canvas, cropArea.width, cropArea.height, cropArea.x, cropArea.y);
  const resizedImage = photon.resize_img_browser(
    photon.open_image(croppedImage, croppedImage.getContext('2d')!),
    outputDimensions.width,
    outputDimensions.height,
    1 /* SamplingFilter.Nearest */
  );
  console.timeEnd('getCroppedImage');

  return new Promise<Blob>((resolve, reject) => resizedImage.toBlob((img) => (img ? resolve(img) : reject()), outputType, outputQuality));
};
