import { mdiFullscreen } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import React from 'react';

export const ImageWithOverlay = ({ image, setEnlargeImage }) => (
  <>
    <img className="h-36 rounded-lg duration-100 hover:z-10 hover:scale-105 hover:shadow-xl lg:h-40" src={image.thumbnail_url} />
    <button
      onClick={() => {
        setEnlargeImage(image.original_url);
      }}
      className={cls(
        'absolute inset-0 flex items-center justify-center rounded-lg bg-gray-500 bg-opacity-50 opacity-0 transition-all',
        'group-hover:opacity-100 group-hover:backdrop-blur-sm'
      )}
    >
      <Icon path={mdiFullscreen} size={2} className="scale-0 text-gray-100 transition-all group-hover:scale-100" />
    </button>
  </>
);
