import {Menu} from '@headlessui/react';
import MenuItem from 'Components/MenuItem';
import MenuItemsWrapper from 'Components/MenuItemsWrapper';
import * as React from 'react';
import {useState} from 'react';
import useUser from 'Support/Hooks/useUser';

const AccountMenu = ({accountMenuItems}) => {
  const {avatar, name} = useUser();
  const [trigger, setTriggerElement] = useState(null);

  return (
    <div className="relative inline-block text-left shrink-0">
      <Menu>
        {({open}) => (
          <>
            <Menu.Button
              ref={setTriggerElement}
              className="max-w-lg text-sm rounded py-2 pl-4 focus-ring flex items-center"
            >
              <div
                aria-haspopup="true"
                aria-label="User menu"
                className="flex items-center text-base sm:space-x-3 max-w-full"
                id="user-menu"
              >
                <img className="h-8 w-8 rounded-full" src={avatar} alt={`${name} avatar`}/>
              </div>
              <svg className="ml-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"/>
              </svg>
            </Menu.Button>

            <MenuItemsWrapper show={open} trigger={trigger} width="w-64" grouped>
              {accountMenuItems?.map((item, index) => (
                <MenuItem icon={item.icon} href={item.href} key={index}>{item.title}</MenuItem>
              ))}
            </MenuItemsWrapper>
          </>
        )}
      </Menu>
    </div>
  );
};

export default AccountMenu;
